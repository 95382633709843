.bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 0;
  overflow: hidden;
  background: black url('./space.gif') no-repeat center center fixed;
  background-size: cover;
}

.bg div {
  position: absolute;
}
