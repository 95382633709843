.launcher {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}
.launch-button {
  background-image: url(./launcher.gif);
  width: 39px;
  min-width: 39px;
  height: 44px;
  min-height: 44px;
  z-index: 1;
}
.launch-label {
  margin: 10px;
  font-size: 14px;
  color: gray;
  text-align: center;
  z-index: 0;
}
