main {
  z-index: 1;
}

/* Frame */
/* preload frame: https://stackoverflow.com/questions/1373142/preloading-css-images */
body::after {
  position: absolute;
  width: 0;
  height: 0;
  overflow: hidden;
  z-index: -1;
  content: url(./frame.png);
}
.frame {
  width: 282px;
  min-width: 282px;
  height: 396px;
  min-height: 396px;
  background-image: url(./frame.png);
}

/* Dropdown */
.dropdown-area {
  width: 100%;
  height: 58px;
  margin-bottom: 5px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}
.dropdown-area select {
  width: 181px;
}

/* Image + caption */
.image-area {
  margin: 0;
  width: 100%;
  /*I am a positioned ancestor that makes the loading indicator's positioning work*/
  position: relative;
}
#main-image {
  width: 246px;
  height: 198px;
  margin-left: 18px;
  /*Stop chrome from making images blurry*/
  image-rendering: -webkit-optimize-contrast;
  cursor: pointer;
}
.caption {
  width: 100%;
  height: 25px;
  margin-top: 7px;
  display: flex;
  color: black;
  justify-content: center;
  align-items: center;
}

/* Nav buttons */
.nav-button-area {
  width: 100%;
  height: 35px;
  margin-top: 6px;
  display: flex;
  justify-content: center;
}
#left,
#right {
  width: 45px;
  min-width: 45px;
  height: 32px;
  margin-right: 8px;
  margin-left: 8px;
}
#left {
  background-image: url(./leftButtonSprite.png);
}
#right {
  background-image: url(./rightButtonSprite.png);
}

/* Close + OK buttons */
.confirmation-button-area {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  height: 46px;
  margin-top: 1px;
}
#ok {
  background-image: url(./okButtonSprite.png);
  margin-right: 4px;
  height: 47px;
  min-height: 47px;
  width: 47px;
  min-width: 47px;
}
#close {
  background-image: url(./closeButtonSprite.png);
  height: 46px;
  min-height: 46px;
  width: 45px;
  min-width: 45px;
  margin-right: 13px;
}
