.top-bar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: black;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  color: white;
  font-size: 14px;
}

.title {
  font-size: 14px;
  font-weight: bold;
  margin: 0;
}

.links a {
  margin: 10px;
  color: white;
}

.separator::after {
  content: '|';
}
